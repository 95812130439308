<template>
  <div class="about">
    <h1>This is an about page</h1>

    <van-nav-bar
      title="标题"
      right-text="按钮"
      left-arrow
    />
  </div>
</template>

<script>

      export default {
            name:"About",
            components:{

            },
            data(){
                return {

                };
            },
            methods:{

            }
      };
</script>
